@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("Roboto"),
    url("./fonts/Roboto/roboto-400-regular.woff2") format("woff2"),
    url("./fonts/Roboto/roboto-400-regular.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-display: auto;
  src: local("Roboto Italic"),
    url("./fonts/Roboto/roboto-400-italic.woff2") format("woff2"),
    url("./fonts/Roboto/roboto-400-italic.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  font-display: fallback;
  src: local("Roboto Light Italic"),
    url("./fonts/Roboto/roboto-300-italic.woff2") format("woff2"),
    url("./fonts/Roboto/roboto-300-italic.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: local("Roboto Bold"),
    url("./fonts/Roboto/roboto-700.woff2") format("woff2"),
    url("./fonts/Roboto/roboto-700.woff") format("woff");
}

@font-face {
  font-family: "Trade Gothic";
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/TradeGothic/TradeGothic-BoldCondTwenty.woff2")
      format("woff2"),
    url("./fonts/TradeGothic/TradeGothic-BoldCondTwenty.woff") format("woff");
}

#cookie-consent {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 999;
  background-color: #008ccc;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.22);
  max-height: 100%;
  overflow-y: auto;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400 !important;

  p {
    margin-bottom: 1rem;
    font-size: inherit;
    line-height: 1.6;
    text-rendering: optimizeLegibility;
    color: #fff !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400 !important;
    color: #fff !important;
    line-height: 1;
    font-family: "Trade Gothic", "Arial Narrow", Arial, sans-serif;
    text-transform: uppercase;
  }

  h1 {
    font-size: 1.83333rem;
  }
}

.cookie-consent-outer {
  padding-top: 1rem;
  padding-bottom: 0;
  height: 100%;
  max-width: 66.66667rem;
  margin-left: auto;
  margin-right: auto;
}

.cookie-consent-inner {
  padding-right: 0.55556rem;
  padding-left: 0.55556rem;
}

.cookie-consent-text {
  color: #fff;
  font-size: 0.825rem;

  h3 {
    margin-bottom: 1rem;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }
}

.cookie-consent-outer {
  .ster--checkbox {
    position: relative;

    input[type="checkbox"] {
      opacity: 0;
      position: absolute;
      top: 0;
    }

    label {
      display: inline-block;
      vertical-align: middle;
      margin: 5px 0;
      cursor: pointer;
      font-size: 1rem;
      text-indent: 0 !important;
      position: relative;
      color: #fff;
    }

    input[type="checkbox"] + label:before {
      content: "";
      background-color: #fff !important;
      border: 1px solid #fff;
      display: inline-block;
      position: relative;
      top: -2px;
      vertical-align: middle;
      width: 24px;
      height: 24px;
      padding: 2px;
      margin-right: 10px;
      text-align: center;
      box-shadow: none !important;
    }

    input[type="checkbox"]:checked + label:before {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16.5 14' style='enable-background:new 0 0 16.5 14;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;%7D .st1%7Bfill:none;stroke:%23008CCC;stroke-width:2;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpath class='st0' d='M0-1h24v24H0V-1z'/%3E%3Cpath class='st1' d='M0.7,8.8c3.7,3.9,3.7,3.9,3.7,3.9L15.2,2.2'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px;
    }
  }
}

.cookie-consent-buttons {
  button {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-image: none;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    white-space: nowrap;
    height: 2.5rem;
    padding: 0 2rem;
    line-height: 2.5;
    width: auto;
    outline: 0;
    margin: 1rem 0;
    font-weight: 700;
    transition: ease-in 0.1s;
    color: #fff;
    background-color: transparent;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 1);
    border: medium none;

    &:hover,
    &:active,
    &:focus {
      background-color: #fff;
      color: #008ccc;
    }
  }
}
